import "react-vertical-timeline-component/style.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 0)
  return false
}
